import async from '../components/atoms/async';

const Subdistrict = async(() => import('src/components/pages/subdistrict'));
const SubdistrictCreate = async(() => import('src/components/pages/subdistrict-create-new'));
const SubdistrictEdit = async(() => import('src/components/pages/subdistrict-edit-new'));

const manageSubdistrict = [
  {
    auth: true,
    path: '/kelurahan',
    key: 'kelurahan',
    layout: 'sidebar',
    component: Subdistrict,
  },
  {
    auth: true,
    path: '/kelurahan/create',
    key: 'detail.kelurahan',
    layout: 'sidebar',
    component: SubdistrictCreate,
    title: 'create_subdistrict',
  },
  {
    auth: true,
    path: '/kelurahan/edit/:id',
    key: 'detail.kelurahan',
    layout: 'sidebar',
    component: SubdistrictEdit,
    title: 'edit_subdistrict',
  },
];

export default manageSubdistrict;
