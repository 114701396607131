import React from "react";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import StorefrontIcon from "@material-ui/icons/Storefront";
import PaymentIcon from "@material-ui/icons/Payment";
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import StorageIcon from '@material-ui/icons/StorageOutlined';
import AccountBalance from '@material-ui/icons/AccountBalance';

export default [
  {
    key: "transaction",
    path: "/transaction",
    name: "Transaction",
    icon: <ReceiptOutlinedIcon />,
    permission: ["ADMIN"],
    children: [
      {
        key: "transaction.monitoring.search",
        path: "/transaction/customer-transaction",
        name: "Customer Transaction",
        role: [],
      },
    ],
  },
  {
    key: "merchant",
    path: "/merchant",
    name: "Merchant Management",
    icon: <StorefrontIcon />,
    permission: ["ADMIN"],
    children: [
      {
        key: "merchant.manage.monitoring",
        path: "/merchant/manage",
        name: "Manage Merchant",
        role: [],
      },
      {
        key: "merchant.deposit.list",
        path: "/merchant/deposit",
        name: "Deposit",
        role: [],
      },
      {
        key: "merchant.deposit.update-balance-approve",
        path: "/merchant/deposit/approval",
        name: "Deposit Approval",
        role: [],
      },
      {
        key: "merchant.manage.approve-reset-credential",
        path: "/merchant/credential/approval",
        name: "Reset Credential Approval",
        role: [],
      },
      {
        key: "merchant.pricing.search",
        path: "/merchant/pricing/search",
        name: "Pricing Management",
        role: [],
      },
    ],
  },
  {
    key: "disbursement",
    path: "/disbursement",
    name: "Disbursement",
    icon: <PaymentIcon />,
    permission: ["ADMIN"],
    children: [
      {
        key: "disbursement.cash.request",
        path: "/disbursement/cash/request",
        name: "Request",
        role: [],
      },
      {
        key: "disbursement.cash.history",
        path: "/disbursement/cash/history",
        name: "History",
        role: [],
      },
    ],
  },
  {
    key: "customer.detail",
    path: "/customer",
    name: "Customer",
    icon: <PeopleOutlineOutlinedIcon />,
    permission: ["ADMIN"],
    children: [
      {
        key: "customer.detail.list",
        path: "/customer",
        name: "Customer Details",
        role: [],
      },
      {
        key: "customer.detail",
        path: "/blacklist",
        name: "Blacklist",
        role: [],
      }
    ],
  },
  {
    key: "account",
    path: "/verification",
    name: "Account Verification",
    icon: <VerifiedUserIcon />,
    permission: ["ADMIN"],
    children: [
      {
        key: "account.verification.list",
        path: "/verification",
        name: "Account Verification Approval",
        role: [],
      },
      {
        key: "account.verification.list",
        path: "/verified",
        name: "Account Verified / Rejected / Blacklist",
        role: [],
      }
    ],
  },
  {
    key: "settings",
    path: "/role",
    name: "Settings",
    icon: <SettingsApplicationsOutlinedIcon />,
    permission: ["ADMIN"],
    children: [
      {
        key: "settings.manage-role.list",
        path: "/role",
        name: "Manage Role",
        role: [],
      },
      {
        key: "settings.manage-users.list",
        path: "/user",
        name: "Manage Users",
        role: [],
      }
    ],
  },
  {
    key: "detail",
    path: "/database",
    name: "Database",
    icon: <StorageIcon />,
    permission: ["ADMIN"],
    children: [
      {
        key: "profession.list",
        path: "/pekerjaan",
        name: "Pekerjaan",
        role: []
      },
      {
        key: "employee.position.list",
        path: "/employee-position",
        name: "Jabatan",
        role: [],
      },
      {
        key: "business-field.list",
        path: "/business-field",
        name: "Manage Bidang Usaha",
        role: [],
      },
      {
        key: "provinsi",
        path: "/provinsi",
        name: "Provinsi",
        role: []
      },
      {
        key: "kota",
        path: "/kota",
        name: "Kota/Kabupaten",
        role: []
      },
      {
        key: "kecamatan",
        path: "/kecamatan",
        name: "Kecamatan",
        role: []
      },
      {
        key: "kelurahan",
        path: "/kelurahan",
        name: "Kelurahan",
        role: []
      },
      {
        key: "product.list",
        path: "/produk",
        name: "Produk",
        role: []
      },
    ],
  },
  {
    key: "check.balance",
    path: "/balance",
    name: "Bank Information",
    icon: <AccountBalance />,
    permission: ["ADMIN"],
    children: [
      {
        key: "check.balance",
        path: "/balance",
        name: "Check Balance",
        role: [],
      },
      {
        key: "transaction.history",
        path: "/mutation",
        name: "Transaction Mutation",
        role: [],
      }
    ],
  },
];
