import "@projectaltair/web-ui-shared-components/lib/types/theme/custom-theme.d";
import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Snackbar from "src/components/atoms/snackbar";
import Modal from "src/components/atoms/modal";
import store from "./redux";
import Routes from "./routes";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
// LOAD GLOBAL STYLING
import "./assets/scss/_app.scss";
// NORMALIZE DEFAULT BROWSER STYLE
import "normalize.css";

const theme = createMuiTheme({
  typography: {},
  palette: {
    customPrimary: {
      darkest: "#027b6f",
      dark: "#008F7F",
      main: "#E71D2F",
      light: "#db4c58",
      lighter: "#db4c58",
    },
    customSecondary: {
      main: "#21BF73",
    },
    customWarning: {
      main: "#DD2C00",
      light: "#FFE4DD",
    },
    customMistyVariant: {
      darkest: "#868686",
      main: "#BDBDBD",
      light: "#EFF0F2",
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "&$focused $notchedOutline": {
          borderColor: "black",
          borderWidth: 1,
        },
      },
    },
  },
});

class App extends React.Component {
  componentDidMount() {
    // Remove `active` className from `.splash` element in `public/index.html`
    if (document.querySelector(".splash")) {
      document.querySelector(".splash").classList.remove("active");
    }
  }

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Routes />
          <ReduxToastr
            timeOut={5000}
            newestOnTop
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
          />
          <Snackbar />
          <Modal />
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
