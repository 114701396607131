import async from '../components/atoms/async';

const District = async(() => import('src/components/pages/district'));
const DistrictCreate = async(() => import('src/components/pages/district-create-new'));
const DistrictEdit = async(() => import('src/components/pages/district-edit-new'));

const manageDistrict = [
  {
    auth: true,
    path: '/kecamatan',
    key: 'kecamatan',
    layout: 'sidebar',
    component: District,
  },
  {
    auth: true,
    path: '/kecamatan/create',
    key: 'detail.kecamatan',
    layout: 'sidebar',
    component: DistrictCreate,
    title: 'create_district',
  },
  {
    auth: true,
    path: '/kecamatan/edit/:id',
    key: 'detail.kecamatan',
    layout: 'sidebar',
    component: DistrictEdit,
    title: 'edit_district',
  },
];

export default manageDistrict;
