import { IAuth, IUserLoginAction, IUserRoleAction } from "src/types/auth/state";
import { IAction } from "./actions";
import * as types from "./constants";

const initialState: IAuth = {
  isLoggedin: false,
  userData: {
    name: undefined,
    role: [],
    treeRole: {},
    id: null,
  },
  loading: {
    role: true,
  },
};

type Action = IAction<IUserLoginAction & IUserRoleAction>;

export default function reducer(state = initialState, actions: Action): IAuth {
  switch (actions.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedin: true,
        userData: {
          ...state.userData,
          name: actions.payload.name,
          id: actions.payload.uid,
        },
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedin: false,
      };
    case types.GET_USER_ROLE:
      return {
        ...state,
        loading: {
          ...state.loading,
          role: actions.payload.loading,
        },
      };
    case types.MAP_USER_ROLE:
      return {
        ...state,
        userData: {
          ...state.userData,
          role: actions.payload.role,
          treeRole: actions.payload.treeRole,
        },
        loading: {
          ...state.loading,
          role: false,
        },
      };
    default:
      return state;
  }
}
