import async from '../components/atoms/async';

const Transaction = async(() => import('src/components/pages/transaction'));
const TransactionDetail = async(
  () => import('src/components/pages/transaction-detail'),
);

const transaction = [
  {
    auth: true,
    path: '/transaction/customer-transaction',
    key: 'transaction.monitoring.search',
    layout: 'sidebar',
    component: Transaction,
  },
  {
    auth: true,
    path: '/transaction/customer-transaction/detail/:transaction_number',
    key: 'transaction.monitoring.search',
    layout: 'sidebar',
    component: TransactionDetail,
  },
];

export default transaction;
