import React from "react";
import Sidebar from "src/components/organisms/sidebar";
import Navbar from "src/components/organisms/navbar";
import { Route, Switch } from "react-router-dom";
import { IState } from "src/types/state";
import { connect } from "react-redux";
import { compose } from "redux";
import lists from "src/routes/private";
import PageRestrict from "src/components/pages/page-restrict";
import DashboardLayout, {
  RenderElementProps,
} from "@projectaltair/web-ui-shared-components/lib/components/layouts/dashboard";
import findRolePermission from "src/utils/role";

interface PropTypes {
  roles?: string[];
}

const VirgoDashboardLayout: React.FunctionComponent<PropTypes> = (
  props: PropTypes
) => {
  const { roles } = props;

  const renderSidebar = ({ onCloseDrawer }: RenderElementProps) => (
    <Sidebar onNavItemPress={onCloseDrawer} />
  );

  const renderNavbar = () => <Navbar />;

  return (
    <DashboardLayout renderSidebar={renderSidebar} renderNavbar={renderNavbar}>
      <Switch>
        {lists.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            component={
              findRolePermission(route.key) ? route.component : PageRestrict
            }
            exact
          />
        ))}
      </Switch>
    </DashboardLayout>
  );
};

const mapStateToProps = (state: IState) => ({
  roles: state.auth.userData.role,
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(React.memo(VirgoDashboardLayout));
