import React from "react";
import "./index.scss";

interface PropTypes {
  children?: React.ReactChild | React.ReactChild[] | Element | Element[];
}

const Default: React.FunctionComponent<PropTypes> = ({
  children = null,
}: PropTypes) => (
  <>
    <div className="layout-default">{children}</div>
  </>
);

export default React.memo(Default);
