import React from "react";
import { toastr } from "react-redux-toastr";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import jwtDecode from "src/utils/jwt";
import { doLogout } from "src/services/auth";
import { getToken, removeToken, removeUid } from "src/utils/local-storage";
import "./_index.scss";

const NavbarComponent = () => {
  // STATE
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const onOpenProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  // ACTION
  let userEmail = "";
  const token = getToken();
  const tokenDecode = jwtDecode(token);
  if (token) {
    userEmail = tokenDecode && tokenDecode.username;
  }
  const doSignOut = () => {
    doLogout(userEmail)
      .then((res: any) => {
        toastr.success(res.message);
      })
      .catch((res) => {
        toastr.warning(res.message);
      });
    window.location.replace("/");
    removeUid();
    removeToken();
  };

  return (
    <div className="navbar flex v-center direction-reverse">
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={onOpenProfile}
      >
        <AccountCircle htmlColor="white" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={doSignOut}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default NavbarComponent;
