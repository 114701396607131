/**
 * Utilities to fetch data when the state is login
 * @module fetcher
 * @param { oneOf[ get, post, put, delete] } Request method
 * @param { string } urlPath
 * @param { object } body
 * @returns { object } JSON
 */

// import jwtDecode from 'src/utils/jwt';
import { getToken, removeToken, removeUid } from 'src/utils/local-storage';

export const ERROR_NO_CONNECTION = [
  'Sorry there is problem. Pleas refresh again.',
];
export const RESPONSE_SUCCESS = 'SUCCESS';
export const RESPONSE_ERROR = 'ERROR';
export const RESPONSE_ERROR_CONNECTION = {
  status: RESPONSE_ERROR,
  errors: ERROR_NO_CONNECTION,
};
export const BASE_URL = process.env.REACT_APP_BASE_URL_API;

export const responseError = (errors) => ({
  status: RESPONSE_ERROR,
  errors,
});

const requestHeaders = (isFile?: boolean) => {
  const token = getToken() || false;

  const Headers: any = { headers: {} };

  if (!isFile) {
    Headers.headers.Accept = 'application/json';
  }

  if (token) {
    Headers.headers.authorization = `Bearer ${token}`;
  }

  return Headers;
};

function handleResponseFile(response) {
  if (response.ok) {
    return response.blob();
  }
  return response.text().then((res) => {
    throw new Error(
      JSON.stringify({
        ...JSON.parse(res),
        status: response.status,
      }),
    );
  });
}

function handleResponse(response, type: "json" | "text" = 'json', isFile?) {
  let transformedRes;
  if (type === 'json') {
    transformedRes = response.json();
  } else if (type === 'text') {
    transformedRes = response.text();
  }
  return transformedRes.then((data) => {
    let transformedData = data;
    if (type === 'text') {
      transformedData = data && JSON.parse(data);
    }
    if (!response.ok) {
      if (response.status === 401) {
        // REMOVE DATA FROM LOCAL STORAGE
        removeToken();
        removeUid();
        window.location.replace('/');
      }
      if (isFile) {
        return Promise.reject(transformedData || response.statusText);
      }
      if (response.status === 500) {
        return Promise.reject(data);
      }
      throw new Error(
        JSON.stringify({
          ...data,
          status: response.status,
        }),
      );
    }
    return transformedData;
  });
}

export const fetcher = {
  get: (urlPath, isFile?: boolean, type?) => fetch(BASE_URL + urlPath, requestHeaders())
    .then((res) => {
      if (isFile) {
        return handleResponseFile(res);
      }
      return handleResponse(res, type);
    })
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  post: (urlPath, params?, isFile?: boolean, type?) => fetch(BASE_URL + urlPath, {
    ...requestHeaders(isFile),
    body: isFile ? params : JSON.stringify(params),
    method: 'POST',
  })
    .then((res) => handleResponse(res, type, isFile))
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  put: (urlPath, params?, isFile?: boolean, type?) => fetch(BASE_URL + urlPath, {
    ...requestHeaders(isFile),
    body: isFile ? params : JSON.stringify(params),
    method: 'PUT',
  })
    .then((res) => handleResponse(res, type, isFile))
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  delete: (urlPath, params?, type?) => fetch(BASE_URL + urlPath, {
    ...requestHeaders(),
    body: JSON.stringify(params),
    method: 'DELETE',
  })
    .then((res) => handleResponse(res, type))
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  put_gstorage: (urlPath, params) => fetch(urlPath, {
    headers: {
      'Content-Type': 'text/csv',
    },
    body: params,
    method: 'PUT',
  })
    .then((result) => result)
    .catch((error) => Promise.reject(error)),

  get_csv: (urlPath) => fetch(urlPath)
    .then((response) => response.text())
    .catch((error) => Promise.reject(error)),
};
