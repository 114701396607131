import { IModal, ISnackbar } from "src/types/app/state";
import * as types from "./constants";

export interface IAction<T> {
  type: string;
  payload: T;
}

export function toggleSidebar() {
  return {
    type: types.SIDEBAR_VISIBILITY_TOGGLE,
  };
}

export function openSnackbar(payload: ISnackbar): IAction<ISnackbar> {
  return {
    type: types.SNACKBAR_TOGGLE,
    payload,
  };
}

export function toggleModal(payload: IModal): IAction<IModal> {
  return {
    type: types.MODAL_TOGGLE,
    payload,
  };
}
