import async from '../components/atoms/async';

const EmployeePosition = async(() => import('src/components/pages/employee-position'));
const EmployeePositionCreate = async(()=> import('src/components/pages/employee-position-create'))
const EmployeePositionDetail = async(()=> import('src/components/pages/employee-position-detail'))

const employeePositionRoutes = [
  {
    auth: true,
    path: '/employee-position',
    key: 'employee.position.detail',
    layout: 'sidebar',
    component: EmployeePosition,
  },
  {
    auth: true,
    path: '/employee-position/create',
    key: 'detail.pekerjaan',
    layout: 'sidebar',
    component: EmployeePositionCreate,
  },
  {
    auth:true,
    path: '/employee-position/detail/:id',
    key: 'employee.position.detail',
    layout: 'sidebar',
    component: EmployeePositionDetail
  }
];

export default employeePositionRoutes;
