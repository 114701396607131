import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import { useFormik } from "formik";
import * as Yup from "yup";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "src/components/atoms/input-new";
import Typography from "@material-ui/core/Typography";
import { fade, makeStyles } from "@material-ui/core/styles";
import Button from "src/components/atoms/button";
import { doForgotPass } from "src/services/auth";
import logo from "src/assets/img/logo/logo.png";
import "./_index.scss";

interface PropTypes {
  backToLogin: (event?: any) => void | any;
}

const styles = makeStyles({
  rootInput: {
    width: "100%",
  },
  customInput: {
    marginTop: 5,
    fontSize: "1.125rem",
    padding: "5px 15px",
    lineHeight: "1.5",
    borderRadius: ".3rem",
    position: "relative",
    background: "none",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    width: "100%",
    height: 25,
    "&:focus": {
      backgroundColor: "#fff",
      boxShadow: `${fade("#153d77", 0.25)} 0 0 0 2px`,
    },
  },
  pointer: {
    cursor: "pointer",
  },
  formControl: {
    width: "100%",
    marginTop: 20,
  },
  paper: {
    height: "100%",
    padding: "20px 0",
  },
  rootPaper: {
    minWidth: "340px",
    padding: "0px 30px",
  },
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ForgotpassForm: React.FunctionComponent<PropTypes> = ({
  backToLogin = () => {},
}: PropTypes) => {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [isSubmitDisabled, setDisableSubmit] = useState(true);
  const handleForgotPass = ({ email }) => {
    setLoading(true);
    doForgotPass(email)
      .then((res: any) => {
        toastr.success(res.message);
        setLoading(false);
        setTimeout(() => backToLogin(), 5000);
      })
      .catch((res) => {
        toastr.warning(res.message);
        setLoading(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleForgotPass(values);
    },
  });
  useEffect(() => {
    if (formik.values.email !== "") {
      if (formik.isValid) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
  }, [formik]);
  return (
    <Paper classes={{ root: classes.rootPaper }} elevation={3}>
      <div className="m-sm-4 paper-login">
        <div className="text-center">
          <img src={logo} alt="Logo Dimiiland" className="login_logo" />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Input
            id="input_email_forgot" // email
            type="text"
            label="Email"
            name="email"
            value={formik.values.email}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.errors.email && formik.touched.email}
            errorMessage={formik.errors.email}
          />
          <div className="mt-3">
            <InputLabel
              id="link_back_login"
              shrink
              htmlFor="bootstrap-input"
              onClick={() => backToLogin()}
              classes={{ root: classes.pointer }}
            >
              Back to login page
            </InputLabel>
          </div>
          <div className="text-center mt-3">
            <Button
              type="submit"
              disabled={loading || isSubmitDisabled}
              loading={loading}
              id="button_forgot"
            >
              <Typography className="label-button">Continue</Typography>
            </Button>
          </div>
        </form>
      </div>
    </Paper>
  );
};

export default ForgotpassForm;
