import async from '../components/atoms/async';

const auditTrailComponent = async(
  () => import('src/components/pages/audit-trail'),
);
const auditTrailRoutes = [
  {
    auth: true,
    path: '/audit-trail/list',
    key: 'account-verification.account-verification-request.list',
    layout: 'sidebar',
    component: auditTrailComponent,
  },
];

export default auditTrailRoutes;
