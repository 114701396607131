import async from '../components/atoms/async';

const Deposit = async(() => import('src/components/pages/deposit'));
const DepositDetail = async(
  () => import('src/components/pages/deposit-detail'),
);
const DepositApproval = async(
  () => import('src/components/pages/deposit-approval'),
);
const DepositApprovalDetail = async(
  () => import('src/components/pages/deposit-approval-detail'),
);
const DepositRequest = async(
  () => import('src/components/pages/deposit-request'),
);

const deposit = [
  {
    auth: true,
    path: '/merchant/deposit',
    key: 'merchant.deposit.list',
    layout: 'sidebar',
    component: Deposit,
  },
  {
    auth: true,
    path: '/merchant/deposit/detail/:transaction_number',
    key: 'merchant.deposit.details',
    layout: 'sidebar',
    component: DepositDetail,
  },
  {
    auth: true,
    path: '/merchant/deposit/approval',
    key: 'merchant.deposit.update-balance-approve',
    layout: 'sidebar',
    component: DepositApproval,
  },
  {
    auth: true,
    path: '/merchant/deposit/approval/:transaction_number',
    key: 'merchant.deposit.update-balance-approve',
    layout: 'sidebar',
    component: DepositApprovalDetail,
  },
  {
    auth: true,
    path: '/merchant/deposit/request',
    key: 'merchant.deposit.update-balance-request',
    layout: 'sidebar',
    component: DepositRequest,
  },
];

export default deposit;
