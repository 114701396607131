import roleRoutes from './role';
import manageUsers from './user';
import auditTrail from './audit-trail';
import home from './home';
import transaction from './transaction';
import deposit from './deposit';
import merchant from './merchant';
import disbursement from './disbursement';
import customer from './customer';
import account from './account';
import employeePositionRoutes from './employee-position';
import province from './province';
import occupation from './occupation';
import city from './city';
import district from './district';
import subdistrict from './subdistrict';
import businessFieldRoutes from './business-field';
import product from './product';
import bankInformation from './bank-information';

export default [
  ...home,
  ...roleRoutes,
  ...employeePositionRoutes,
  ...businessFieldRoutes,
  ...manageUsers,
  ...auditTrail,
  ...transaction,
  ...deposit,
  ...merchant,
  ...disbursement,
  ...customer,
  ...account,
  ...province,
  ...occupation,
  ...city,
  ...district,
  ...subdistrict,
  ...product,
  ...bankInformation
];
