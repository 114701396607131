import async from '../components/atoms/async';

const City = async(() => import('src/components/pages/city'));
const CityCreate = async(() => import('src/components/pages/city-create-new'));
const CityEdit = async(() => import('src/components/pages/city-edit-new'));

const manageCity = [
  {
    auth: true,
    path: '/kota',
    key: 'kota',
    layout: 'sidebar',
    component: City,
  },
  {
    auth: true,
    path: '/kota/create',
    key: 'detail.kota',
    layout: 'sidebar',
    component: CityCreate,
    title: 'create_city',
  },
  {
    auth: true,
    path: '/kota/edit/:id',
    key: 'detail.kota',
    layout: 'sidebar',
    component: CityEdit,
    title: 'edit_city',
  },
];

export default manageCity;
