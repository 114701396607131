import async from '../components/atoms/async';

const BusinessField = async(() => import('src/components/pages/business-field'));
const BusinessFieldCreate = async(()=> import('src/components/pages/business-field-create'))
const BusinessFieldDetail = async(()=> import('src/components/pages/business-field-detail'))

const businessFieldRoutes = [
  {
    auth: true,
    path: '/business-field',
    key: 'business.field.list',
    layout: 'sidebar',
    component: BusinessField,
  },
  {
    auth: true,
    path: '/business-field/create',
    key: 'business.field.create',
    layout: 'sidebar',
    component: BusinessFieldCreate,
  },
  {
    auth:true,
    path: '/business-field/detail/:id',
    key: 'business.field.detail',
    layout: 'sidebar',
    component: BusinessFieldDetail
  }
  
];

export default businessFieldRoutes;
