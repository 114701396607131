import async from '../components/atoms/async';

const Province = async(() => import('src/components/pages/province'));
const ProvinceCreate = async(() => import('src/components/pages/province-create-new'));
const ProvinceEdit = async(() => import('src/components/pages/province-edit-new'));

const manageProvince = [
  {
    auth: true,
    path: '/provinsi',
    key: 'provinsi',
    layout: 'sidebar',
    component: Province,
  },
  {
    auth: true,
    path: '/provinsi/create',
    key: 'detail.provinsi',
    layout: 'sidebar',
    component: ProvinceCreate,
    title: 'create_province',
  },
  {
    auth: true,
    path: '/provinsi/edit/:id',
    key: 'detail.provinsi',
    layout: 'sidebar',
    component: ProvinceEdit,
    title: 'edit_province',
  },
];

export default manageProvince;
