import async from '../components/atoms/async';

const Product = async(() => import('src/components/pages/manage-product'));
const ProductCreate = async(() => import('src/components/pages/manage-create-product'));
const ProductEdit = async(() => import('src/components/pages/manage-edit-product'));

const manageProvince = [
  {
    auth: true,
    path: '/produk',
    key: 'kelurahan',
    layout: 'sidebar',
    component: Product,
  },
  {
    auth: true,
    path: '/produk/create',
    key: 'detail.provinsi',
    layout: 'sidebar',
    component: ProductCreate,
    title: 'create_product',
  },
  {
    auth: true,
    path: '/produk/edit/:id',
    key: 'detail.provinsi',
    layout: 'sidebar',
    component: ProductEdit,
    title: 'edit_product',
  },
];

export default manageProvince;
