import async from '../components/atoms/async';

const CheckBalance = async(
    () => import('src/components/pages/bank-information-balance')
)

const Mutation = async(
    () => import('src/components/pages/bank-information-mutation')
)

const MutationDetail = async(
    () => import('src/components/pages/bank-information-mutation-detail')
)

const MutationInfo = async(
    () => import('src/components/pages/bank-information-mutation-info')
)

const bankInformation = [
    {
        auth: true,
        path: '/balance',
        key: 'dmi',
        layout: 'sidebar',
        component: CheckBalance,
    },
    {
        auth: true,
        path: '/mutation',
        key: 'dmi',
        layout: 'sidebar',
        component: Mutation,
    },
    {
        auth: true,
        path: '/mutation/detail/:user_id',
        key: 'dmi',
        layout: 'sidebar',
        component: MutationDetail,
    },
    {
        auth: true,
        path: '/mutation/detail/:user_id/info',
        key: 'dmi',
        layout: 'sidebar',
        component: MutationInfo,
    },
];
export default bankInformation;
