import async from '../components/atoms/async';

const customerList = async(
  () => import('src/components/pages/customer-detail'),
);

const customerDetail = async(
  () => import('src/components/pages/customer-detail-form'),
);

const blacklist = async(
  () => import('src/components/pages/customer-blacklist'),
);

const blacklistCreate = async(
  () => import('src/components/pages/customer-blacklist-create'),
);

const blacklistEdit = async(
  () => import('src/components/pages/customer-blacklist-edit'),
);

const blacklistDetail = async(
  () => import('src/components/pages/customer-blacklist-detail'),
);

const customer = [
  {
    auth: true,
    path: '/customer',
    key: 'customer.detail.list',
    layout: 'sidebar',
    component: customerList,
  },
  {
    auth: true,
    path: '/customer/detail/:user_id',
    key: 'customer.detail',
    layout: 'sidebar',
    component: customerDetail,
  },
  {
    auth: true,
    path: '/blacklist',
    key: 'customer.detail',
    layout: 'sidebar',
    component: blacklist,
  },
  {
    auth: true,
    path: '/blacklist/create',
    key: 'customer.detail',
    layout: 'sidebar',
    component: blacklistCreate,
  },
  {
    auth: true,
    path: '/blacklist/edit/:id',
    key: 'customer.detail',
    layout: 'sidebar',
    component: blacklistEdit,
  },
  {
    auth: true,
    path: '/blacklist/detail/:id',
    key: 'customer.detail',
    layout: 'sidebar',
    component: blacklistDetail,
  },
];
export default customer;
