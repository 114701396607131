import async from '../components/atoms/async';

const Occupation = async(() => import('src/components/pages/occupation'));
const OccupationCreate = async(() => import('src/components/pages/occupation-create-new'));
const OccupationEdit = async(() => import('src/components/pages/occupation-edit-new'));

const manageOccupation = [
  {
    auth: true,
    path: '/pekerjaan',
    key: 'pekerjaan',
    layout: 'sidebar',
    component: Occupation,
  },
  {
    auth: true,
    path: '/pekerjaan/create',
    key: 'detail.pekerjaan',
    layout: 'sidebar',
    component: OccupationCreate,
    title: 'create_pekerjaan',
  },
  {
    auth: true,
    path: '/pekerjaan/edit/:id',
    key: 'detail.pekerjaan',
    layout: 'sidebar',
    component: OccupationEdit,
    title: 'edit_pekerjaan',
  },
];

export default manageOccupation;
