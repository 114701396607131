import asyncComponent from '@projectaltair/web-ui-shared-components/lib/components/atoms/async';

const DisbursementRequestList = asyncComponent(
  () => import('src/components/pages/disbursement-request-list'),
);
const DisbursementHistoryList = asyncComponent(
  () => import('src/components/pages/disbursement-history-list'),
);

const disbursement = [
  {
    auth: true,
    path: '/disbursement/cash/request',
    key: 'disbursement.cash.request',
    layout: 'sidebar',
    component: DisbursementRequestList,
  },
  {
    auth: true,
    path: '/disbursement/cash/history',
    key: 'disbursement.cash.history',
    layout: 'sidebar',
    component: DisbursementHistoryList,
  },
];
export default disbursement;
