import React from "react";
import { Link } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import Button from "src/components/atoms/button";

const PageRestrict = () => (
  <div className="text-center" style={{ marginTop: 260 }}>
    <BlockIcon
      className="align-middle"
      style={{ fontSize: 100, marginBottom: 20, color: "#fa5a4f" }}
    />
    <p className="h1">This page is restricted</p>
    <p className="h2 font-weight-normal mt-3 mb-4">
      You don&apos;t have permission to view this page.
    </p>
    <Link to="/">
      <Button color="primary" id="buttonBackToHome">
        Back to Homepage
      </Button>
    </Link>
  </div>
);

export default PageRestrict;
