import async from '../components/atoms/async';

const MerchantManage = async(
  () => import('src/components/pages/merchant-manage'),
);
const MerchantCreateNew = async(
  () => import('src/components/pages/merchant-create-new'),
);
const MerchantManageDetail = async(
  () => import('src/components/pages/merchant-manage-detail'),
);
const MerchantEdit = async(() => import('src/components/pages/merchant-edit'));
const MerchantActivation = async(
  () => import('src/components/pages/merchant-activation'),
);
const MerchantPricing = async(
  () => import('src/components/pages/merchant-edit-pricing'),
);
const MerchantResetApproval = async(
  () => import('src/components/pages/merchant-reset-credential-approval'),
);

const MerchantPricingManagement = async(
  () => import('src/components/pages/merchant-pricing'),
);

const MerchantPricingEdit = async(
  () => import('src/components/pages/merchant-pricing-edit'),
);
const MerchantEditFeatures = async(
  () => import('src/components/pages/merchant-edit-features'),
);

const merchant = [
  {
    auth: true,
    path: '/merchant/manage',
    key: 'merchant.manage.monitoring',
    layout: 'sidebar',
    component: MerchantManage,
  },
  {
    auth: true,
    path: '/merchant/detail/:id',
    key: 'merchant.manage.monitoring',
    layout: 'sidebar',
    component: MerchantManageDetail,
  },
  {
    auth: true,
    path: '/merchant/create/new',
    key: 'merchant.manage.onboard',
    layout: 'sidebar',
    component: MerchantCreateNew,
  },
  {
    auth: true,
    path: '/merchant/edit/:id',
    key: 'merchant.manage.edit',
    layout: 'sidebar',
    component: MerchantEdit,
  },
  {
    auth: true,
    path: '/merchant/activate/service',
    key: 'merchant.manage.activate-service',
    layout: 'sidebar',
    component: MerchantActivation,
  },
  {
    auth: true,
    path: '/merchant/pricing/service',
    key: 'merchant.manage.service-pricing',
    layout: 'sidebar',
    component: MerchantPricing,
  },
  {
    auth: true,
    path: '/merchant/credential/approval',
    key: 'merchant.manage.approve-reset-credential',
    layout: 'sidebar',
    component: MerchantResetApproval,
  },
  {
    auth: true,
    path: '/merchant/pricing/search',
    key: 'merchant.pricing.search',
    layout: 'sidebar',
    component: MerchantPricingManagement,
  },
  {
    auth: true,
    path: '/merchant/pricing/edit-price',
    key: 'merchant.pricing.edit-price',
    layout: 'sidebar',
    component: MerchantPricingEdit,
  },
  {
    auth: true,
    path: '/merchant/activate-services/:id',
    key: 'merchant.manage.activate-service',
    layout: 'sidebar',
    component: MerchantEditFeatures,
  },
];

export default merchant;
