import async from '../components/atoms/async';

const accountList = async(
  () => import('src/components/pages/account-verification-approval'),
);

const accountForm = async(
  () => import('src/components/pages/account-verification-form'),
);

const verifiedList = async(
  () => import('src/components/pages/account-verified-reject'),
);

const verifiedForm = async(
  () => import('src/components/pages/account-verified-form'),
);

//HARDCODE KEY
const account = [
  {
    auth: true,
    path: '/verification',
    key: 'account.verification.list',
    layout: 'sidebar',
    component: accountList,
  },
  {
    auth: true,
    path: '/verification/approval/:user_id',
    key: 'account.verification.detail',
    layout: 'sidebar',
    component: accountForm,
  },
  {
    auth: true,
    path: '/verified',
    key: 'account.verification.detail',
    layout: 'sidebar',
    component: verifiedList,
  },
  {
    auth: true,
    path: '/verified/detail/:user_id',
    key: 'account.verification.detail',
    layout: 'sidebar',
    component: verifiedForm,
  },
];
export default account;
