import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Paper from "@material-ui/core/Paper";
import { fade, makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";

import Button from "src/components/atoms/button";
import logo from "src/assets/img/logo/logo.png";
import Input from "src/components/atoms/input-new";
import { login } from "src/redux/auth/actions";

import "./_index.scss";

interface PropTypes {
  forgotPass: (event?: any) => void | any;
}

const styles = makeStyles({
  rootInput: {
    width: "100%",
  },
  customInput: {
    marginTop: 5,
    fontSize: "1.125rem",
    padding: "5px 15px",
    lineHeight: "1.5",
    borderRadius: ".3rem",
    position: "relative",
    background: "none",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    width: "100%",
    height: 25,
    "&:focus": {
      backgroundColor: "#fff",
      boxShadow: `${fade("#153d77", 0.25)} 0 0 0 2px`,
    },
  },
  checkbox: {
    color: "#3b7ddd",
    fontSize: 11,
  },
  formControl: {
    width: "100%",
    marginTop: 20,
  },
  iconButton: {
    "&:focus": {
      outline: "none",
    },
  },
  pointer: {
    cursor: "pointer",
  },
  rootPaper: {
    padding: "0px 30px",
  },
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Must be longer than 6 characters")
    .max(32, "Must be 32 characters or less")
    .required("Password is required"),
});

const SigninForm: React.FunctionComponent<PropTypes> = ({
  forgotPass = () => {},
}: PropTypes) => {
  // CORE HOOKS
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [remember, setRemember] = useState(false);
  // OTHER NECESITIES
  const dispatch = useDispatch();
  const classes = styles();
  const callback = (success: boolean) => {
    setLoading(false);
    if (success) {
      window.location.replace("/");
    }
  };
  const handleLogin = ({ email, password }) => {
    setLoading(true);
    dispatch(
      login(
        {
          email,
          password,
        },
        callback
      )
    );
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });
  return (
    <Paper classes={{ root: classes.rootPaper }} elevation={3}>
      <div className="m-sm-4 paper-login">
        <div className="text-center">
          <img src={logo} alt="Logo Dimiiland" className="login_logo" />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <Input
              id="input_email_login" // email
              type="text"
              label="Email"
              name="email"
              value={formik.values.email}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={formik.errors.email && formik.touched.email}
              errorMessage={formik.errors.email}
            />
          </div>
          <div className="mb-3">
            <Input
              label="Password"
              name="password"
              type={showPass ? "text" : "password"}
              value={formik.values.password}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              id="input_password_login" // password
              showPass
              classVisible={{ root: classes.iconButton }}
              togglePass={() => setShowPass(!showPass)}
              error={formik.errors.password && formik.touched.password}
              errorMessage={formik.errors.password}
            />
          </div>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            onClick={forgotPass}
            classes={{ root: classes.pointer }}
          >
            Reset Password ?
          </InputLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={remember}
                onChange={() => setRemember(!remember)}
                name="rememberMe"
                classes={{ root: classes.checkbox }}
                color="primary"
                size="small"
                id="checkbox_remember"
              />
            }
            label={
              <span style={{ fontSize: "11px" }}>Remember me next time</span>
            }
          />
          <div className="text-center mt-3">
            <Button
              id="button_login"
              disabled={loading}
              loading={loading}
              type="submit"
            >
              <Typography className="label-button">Sign In</Typography>
            </Button>
          </div>
        </form>
      </div>
    </Paper>
  );
};

export default SigninForm;
