import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const makeCustomStyles = makeStyles((theme: Theme) =>
  createStyles({
    listRoot: {},
    listTextPrimary: {
      fontWeight: 700,
      fontSize: "15px",
      color: "black",
    },
    subListTextPrimary: {
      fontSize: "15px",
      color: "#546e7a",
    },
    subListTextPrimaryActive: {
      fontSize: "15px",
      color: "black",
    },
    listIconRoot: {
      minWidth: 40,
    },
    nested: {
      paddingLeft: theme.spacing(7),
    },
    skeletonRoot: {
      margin: "5px",
    },
  })
);

export default makeCustomStyles;
